.partners_container{
    font-family: poppins;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    margin-top: 100px;
    opacity: 0.9;
}
.overlay_color{
    background: rgba(0, 0, 0, 0.8);;
}
.partners_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.partners{
    background: url("../../assets/partner-bg.jpg");
    background-position: center;
    
}

.partners h1{
    font-family: poppins;
    font-size: 60px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 80px;
}
.partners_box{
    width: 60%;
    margin: 100px 20px;
}
.brands_container{
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: initial;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}