.banner_svg{
    height: 700px;
    float: right;
    color: antiquewhite;
}
.banner_container{
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    min-height: 700px;
}
.container{
    max-width: 1140px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}
.slide:nth-child(1)::before{

    background: url(../../assets/banner-claimb.jpg);
    background-size: cover;
    height: 700px;
    float: right;
    width: 100%;
    position: absolute;
    background-position: left;
    content: '';
    top: 0;
    right: 0;
    mix-blend-mode: screen;
}
.slide:nth-child(2)::before{

    background: url(../../assets/banner-claimb.jpg);
    background-size: cover;
    height: 700px;
    float: right;
    width: 100%;
    position: absolute;
    background-position: left;
    content: '';
    top: 0;
    right: 0;
    mix-blend-mode: screen;
}
.slide:nth-child(3)::before{

    background: url(../../assets/banner-claimb.jpg);
    background-size: cover;
    height: 700px;
    float: right;
    width: 100%;
    position: absolute;
    background-position: left;
    content: '';
    top: 0;
    right: 0;
    mix-blend-mode: screen;
}

  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    height: 700px;
    opacity: 0;
    animation: banner_container 30s infinite;
  }
  
  .slide:nth-child(1) {
    background-image: url(../../assets/banner-image-1.jpg);
    animation-delay: 0s;
    background-size: cover;
    height: 700px;
    float: right;
    width: 100%;
  }
  
  .slide:nth-child(2) {
    background-image: url(../../assets/banner-image-2.jpg);
    animation-delay: 10s;
    background-size: cover;
    height: 700px;
    float: right;
    width: 100%;
  }
  
  .slide:nth-child(3) {
    background-image: url(../../assets/banner-image-3.jpg);
    animation-delay: 10s;
    background-size: cover;
    height: 700px;
    float: right;
    width: 100%;
  }

  .app__logo{
    width: 160px;
}
.navi__align{
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
}

.top__navi{
  padding: 0;
}

.megamenu{
    display: flex;
}
  
  @keyframes banner_container {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    33% {
      opacity: 1;
    }
    53% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }