@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@200;300;400;500;600;700;800&family=Montserrat:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap');


:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #7776ff 1.84%, #ae2cf1 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #4facfe -13.86%, #00f2fe 99.55%);
  --gradient-bar-light: linear-gradient(180deg, #ffffff 70%, #e3e3e3 99.55%);

  
  --color-bg: #ffffff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: unset;
  text-decoration: none;
}

a:hover{
  color: #fc3a3a;
}
.cls-1{
  background: #7776ff;
  fill: #ae2cf1;
  stroke: #FF8A71;
}
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: #f66f66;
  padding: 5px 10px;
}

::selection {
  color: #fff;
  background: #f66f66;
  padding: 5px 10px;

}
button{
  height: 40px;
  padding: 4px 24px
}
li{
  text-decoration: none;
  list-style-type: none;
}
