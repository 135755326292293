.news{
    font-family: poppins;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #ffffff;
    color: #000000;
}
.news h1{
    font-family: poppins;
    font-size: 60px;
    text-transform: uppercase;
    color: #000000;
    margin-top: 80px;
}
.news_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 50px;
}
.blog_container{
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.blog_content{
    padding: 6%;
    border: 1px solid #e5e5e5;
}
.blog_image{
    width: 100%;
    opacity: 75%;

}
.blog_image:hover{

    opacity: 100%;
    transition: 0.5s;
}
.blog_heading{
    margin-top: 10px;
}
.blog_heading button{
    height: 40px;
    padding: 4px 24px;
    margin-top: 5px;
}
.ant-btn-default:not(:disabled):hover{
    border: 1px solid #ea8480;
}


.blog_heading h3{
    font-size: 24px;
    height: 60px;
    position: relative;
    overflow: hidden;
    font-family: 'Lato';
    margin: 10px 0;
    letter-spacing: .05em;
}
.blog_heading span{
    float: left;
    color: #8b8b8b;
    font-size: 16px;
}
.blog_heading p{
    color: #565656;
    font-size: 16px;
    position: relative;
    left: 6%;
}
.gutter-row{
    margin: 0;
}
