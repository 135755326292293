.services{
    font-family: poppins;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
}
.services h1{
    font-family: poppins;
    font-size: 60px;
    text-transform: uppercase;
    color: #000;
}
.services_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}