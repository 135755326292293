.services_banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/career-banner.jpg);
    background-position: center center;
    height: 250px;

}
.services_banner h1{
    color: #fff;
    font-weight: 700;
}
.contactus_page h1{
    font-size: 54px;
    font-family: 'Sora', 'poppins';
    font-weight: 500;
    text-transform: none;
    color: #000;
    position: relative;
}


.contactus_page h2{
    font-size: 21px;
    font-family: 'Sora', 'poppins';
    text-transform: none;

}
.contactus_page{
    background-color: #f7f7f7;
}
.contactus_box_container p{
    font-size: 14px;
    bottom: 30px;
    position: relative;
}

.contactus_box_container h2{
    margin: 20px 0;
    bottom: 30px;
    position: relative;
}
.contactus_box_container{
    padding: 5%;
}
.services_page{
    position: relative;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
}
.contactus_box{
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 20px;
    transition: 1s;
    margin: 2% 2% 0 2%;
}
.contactus_box:hover{
    border: 1px solid #ff6363;
    
}
.contactus_box:hover .contactus_icons{
    border: 1px solid #fff;
    color: #dedede;
    background: #ff6363;
    
}
.contactus_icons{
    font-size: 24px;
    border-radius: 50%;
    background: #f7f7f7;
    height: 65px;
    color: #ff6363;
    width: 65px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #dedede;
    position: relative;
    bottom: 52.5px;
    transition: 1s;
}
.contactus_form p {
    font-size: 14px;
    position: relative;
    bottom: 0;
}
.contactus_form {

    padding: 30px;
}
.contactus_image{
    background: url(../../assets/contactus-image.jpg) no-repeat;
    background-size: 620px ;
    height: 100%;
    border-radius: 20px;
}