.megamenu_image1{
    background-size: 450px;
    min-height: 300px;
    width: 450px;
}
.megamenu_main{
    background: #fff;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-light:not(.ant-menu-horizontal), :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu:not(.ant-menu-horizontal) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.container{
    max-width: 1140px;
    margin: 0 auto;
    background: #fff;
    
    
}
:where(.css-dev-only-do-not-override-12jzuas).ant-space-align-center {
    align-items: flex-start !important;
    margin-top: 20px;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title{
    text-align: left;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #fff !important;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected {
    color: #fff !important;
    background-color: #ff3a3a !important;
}
.megamenu_list{
    display: flex;
    flex-direction: column !important;
    width: 250px;
    box-shadow: none !important;
    border: none !important;
}
.ant-menu-submenu{
    left: 0 !important;
    background: #fff;
}
.ant-menu-submenu-popup{
    width: 100%;
    margin: 0 auto;
    background: #fff;

}