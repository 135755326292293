.contactform{
    position: relative;
    margin: 30px 0;
}
.contactform input,.contactform textarea {
    font-size: 14px;
    padding: 10px;
    display: block;
    width: 100%;
    margin: 10px 0;
    border: 1px solid #dedede;
}
.contactform textarea {
    min-height: 120px;
    width: 100%;
}
.contactform_rows{
    position: relative;
}
.contactform button{
    background: #fc3a3a;
    color: #fff;
    border: 2px solid #f7f7f7;
    padding: 12px 50px;
    font-size: 18px;
    height: 50px;
    border-radius: 6px;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.5s;
    text-transform: uppercase;
    position: relative;
    bottom: 0;
}
.contactform button:hover{
    background: #fff;
    color: #000;
    border: 2px solid #fc3a3a;
    position: relative;
    bottom: 2px;
    
}
