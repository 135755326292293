.offers{
    font-family: poppins;
    display: flex;

    flex-direction: column;
    margin-top: 100px;
    font-weight: 700;
    
}
.offers h1{
    font-family: poppins;
    font-size: 200px;
    text-transform: uppercase;
    /* <color> values */
    -webkit-text-stroke: 0.22vh #fc3a3a;
    color: #fff;
    position: relative;
    left: 100px;
    font-weight: 700;
}


/* Global values */


.offers h2{
    font-family: poppins;
    font-size: 120px;
    text-transform: uppercase;
    -webkit-text-stroke: 1px #353535;
    color: #fff;
    position: relative;
    left: 100px;
    top: 80px;
    margin-top: 60px;
}
.offers_box{
    position: relative;
    left: 100px;
}
