.services_banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/services-banner.jpg);
    background-position: center center;
    height: 250px;

}
.services_banner h1{
    color: #fff;
    font-weight: 700;
}
h1{
    font-size: 42px;
    font-family: poppins;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
}
h2{
    font-size: 30px;
    font-family: poppins;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
}
.app_development_page{
    background-color: #f7f7f7;
}
.app-development_box h2{
    font-size: 16px;
    font-family: poppins;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    transition: 1s;
}
.app-development_box_container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin: 1% 0;
    padding: 0 2.5%;
    
}
.app-development_box{
    display: flex;
    background: #ffffff;
    flex-direction: column;
    border-radius: 20px;
    height: 200px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
cursor: pointer;
transition: 0.5s;
margin: 2.5%;
justify-content: center;
  
}

.box_image1{
    background: #ffffff url(../../assets/app-dev-box-image1.jpg) no-repeat 0 0;
    background-size: 330px;
}
.box_image2{
    background: #ffffff url(../../assets/app-dev-box-image2.jpg) no-repeat 0 0;
    background-size: 330px;
}
.box_image3{
    background: #ffffff url(../../assets/app-dev-box-image3.jpg) no-repeat 0 0;
    background-size: 330px;
}
.box_image4{
    background: #ffffff url(../../assets/app-dev-box-image4.jpg) no-repeat 0 0;
    background-size: 330px;
}


.box_icon2{
    background: #ffffff url(../../assets/service-icon-bg-2.svg) no-repeat 140px 0;
}

.box_icon2:hover{
    background: #ff6363 url(../../assets/service-icon-bg-2.svg) no-repeat 140px -140px;
}
.box_icon3{
    background: #ffffff url(../../assets/service-icon-bg-3.svg) no-repeat 140px 0;
}

.box_icon3:hover{
    background: #ff6363 url(../../assets/service-icon-bg-3.svg) no-repeat 140px -140px;
}
.box_icon4{
    background: #ffffff url(../../assets/service-icon-bg-4.svg) no-repeat 140px 0;
}

.box_icon4:hover{
    background: #ff6363 url(../../assets/service-icon-bg-4.svg) no-repeat 140px -140px;
}
.box_icon5{
    background: #ffffff url(../../assets/service-icon-bg-5.svg) no-repeat 140px 0;
}

.box_icon5:hover{
    background: #ff6363 url(../../assets/service-icon-bg-5.svg) no-repeat 140px -140px;
}
.box_icon6{
    background: #ffffff url(../../assets/service-icon-bg-6.svg) no-repeat 140px 0;
}

.box_icon6:hover{
    background: #ff6363 url(../../assets/service-icon-bg-6.svg) no-repeat 140px -140px;
}
.box_icon7{
    background: #ffffff url(../../assets/service-icon-bg-7.svg) no-repeat 140px 0;
}

.box_icon7:hover{
    background: #ff6363 url(../../assets/service-icon-bg-7.svg) no-repeat 140px -140px;
}





.app-development_box p{
    font-size: 13px;
    line-height: 21px;
    transition: 1s;
    color: #dedede;

}
.app-development_box_container h2{
    margin: 20px 0;
}
.services_page{
    position: relative;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
}
.align_margin{
    margin: 5% 5% 5% 5%;
    
}
.svg_icons{
    fill: #fc3a3a;
    transition: 1s;

}
p{
    font-size: 18px;
    line-height: 30px;;
}

.line{
    height: 8px;
    width: 150px;
    background: #dedede;
    margin-bottom: 20px;
    margin-top: 10px;
}
strong{
    font-style: italic;
}
.services_div_right{
    display: flex;
    justify-content: center;
    flex-direction: column;
}



.svg_icons{
    width: 80px;
    height: 80px;
    transition: 1s;

}
.svg_icons_bg{
    position: relative;

}

.services_tech{
    background: #000;
    padding: 100px 5%;
    margin-top: 100px;
}
.services_tech h1, .services_tech p{
    color: #ffffff;    
    justify-items: center;
}
.brand_list{
    width: 100%;
    min-height: 100px;
    border: 1px solid #333;
    border-top: none;
    border-left: none;
}
.brand_list:nth-child(4), .brand_list:nth-child(8), .brand_list:nth-child(12), .brand_list:nth-child(16){
    border-right: none;
}
.brand_list:nth-child(13), .brand_list:nth-child(14), .brand_list:nth-child(15), .brand_list:nth-child(16){
    border-bottom: none;
}
.brand_list:nth-child(1){
    background: url(../../assets/aws.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(2){
    background: url(../../assets/microsoft.svg) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(3){
    background: url(../../assets/ibm.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(4){
    background: url(../../assets/oracle.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(5){
    background: url(../../assets/aws.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(6){
    background: url(../../assets/microsoft.svg) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(7){
    background: url(../../assets/ibm.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(8){
    background: url(../../assets/oracle.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(9){
    background: url(../../assets/aws.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(10){
    background: url(../../assets/microsoft.svg) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(11){
    background: url(../../assets/ibm.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(12){
    background: url(../../assets/oracle.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(13){
    background: url(../../assets/aws.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(14){
    background: url(../../assets/microsoft.svg) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(15){
    background: url(../../assets/ibm.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.brand_list:nth-child(16){
    background: url(../../assets/oracle.png) no-repeat;
    background-size: 100px;
    background-position: center;
}
.app_dev_s2{
    background: #fff;
}


.app_development_page .bg_box1{
    background: #eee url(../../assets/app-development-image.png);
    background-size: 500px;
    width: 500px;
    height: 500px;
    z-index: 2;
    position: relative;
    border: 1px solid #dedede;
}

.app_dev_s2_box{
    display: flex;
    flex-direction: column;
    margin: 4% 4% 0 0;
    padding: 20px;
    background: #fff;
    transition: 0.5s;
    border-radius: 20px;
    position: relative;
    bottom: 0;
   
}
.app_dev_s2_box:hover{

    background: #fc3a3a;
   
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
position: relative;
   
}
.app_dev_s2_box:hover h3, .app_dev_s2_box:hover p, .app_dev_s2_box:hover .svg_icons{

    color: #fff;
    fill: #f7f7f7;
    
   
}
   


.margin_top{
    padding: 100px 0;
}
.app_dev_s2_box p{
font-size: 14px;
line-height: 24px;
transition: 0.5s;
}
.app_dev_s2_box h3{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 0;
    transition: 0.5s;

    }
