.box{
    background: #fc3a3a;
    opacity: 75%;
    min-height: 200px;
    padding: 20px;
    margin: 0 1%;
    border-radius: 10px;
    
}
.container{
    background-color: unset;
}
.serviceboxes{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 100px;
}
.box h2{
    margin: 1vh 1vh;
    color: #fff;
    line-height: 24px;
    font-size: 20px !important;
    font-weight: 400 !important;
}
.boxicon{
    width: 64px;
}
.box p{
    margin: 0vh 1vh;
    color: #fff;
    font-size: 13px;
    line-height: 18px;
}