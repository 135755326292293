.services_banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/services-banner.jpg);
    background-position: center center;
    height: 250px;

}
.services_banner h1{
    color: #fff;
    font-weight: 700;
}
h1{
    font-size: 42px;
    font-family: poppins;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
}
h2{
    font-size: 30px;
    font-family: poppins;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
}
.analytics_page{
    background-color: #f7f7f7;
}




.analytics_box_container h2{
    margin: 20px 0;
}
.services_page{
    position: relative;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
}
.align_margin{
    margin: 5% 5% 5% 5%;
    
}
.svg_icons{
    fill: #fc3a3a;
    transition: 1s;

}
p{
    font-size: 18px;
    line-height: 30px;;
}

.line{
    height: 8px;
    width: 150px;
    background: #dedede;
    margin-bottom: 20px;
    margin-top: 10px;
}
strong{
    font-style: italic;
}
.services_div_right{
    display: flex;
    justify-content: center;
    flex-direction: column;
}



.svg_icons{
    width: 80px;
    height: 80px;
    transition: 1s;

}
.svg_icons_bg{
    position: relative;

}


.analytics_s2{
    background: #fff;
}
.analytics_box_container{
    padding-bottom: 5%;
}


.analytics_page .bg_box1{
    background: #eee url(../../assets/analytics-image.jpg);
    background-size: 500px;
    width: 500px;
    height: 500px;
    z-index: 2;
    position: relative;
    border: 1px solid #dedede;
}

.analytics_s2_box{
    display: flex;
    flex-direction: column;
    margin: 4% 4% 0 0;
    padding: 20px;
    background: #fff;
    transition: 0.5s;
    border-radius: 20px;
    position: relative;
    bottom: 0;
   
}
.analytics_s2_box:last-child{
    margin: 4% 0 0 0;
}
.analytics_s2_box:hover{

    background: #fc3a3a;
   
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
position: relative;
   
}
.analytics_s2_box:hover h3, .analytics_s2_box:hover p, .analytics_s2_box:hover .svg_icons{

    color: #fff;
    fill: #f7f7f7;
    
   
}
   


.margin_top{
    padding: 100px 0;
}
.analytics_s2_box p{
font-size: 14px;
line-height: 24px;
transition: 0.5s;
}
.analytics_s2_box h3{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 0;
    transition: 0.5s;

    }
    @media (min-width: 769px) and (max-width: 1024px){
        .analytics_page .bg_box1 {
            background-size: 400px;
            width: 400px;
            height: 400px;
            
        }
        .analytics_page p {
            font-size: 14px;
            line-height: 24px;
        }

    
    }
    @media (max-width: 768px){
     .analytics_page .bg_box1 {
        background-size: 350px;
        width: 350px;
        height: 350px;
    }
    .analytics_page h1 {
        font-size: 30px;

    }
    .analytics_page p {
        font-size: 14px;
        line-height: 24px;
    }
}