

.navi__header{
    background: #fff;
}

li.ant-menu-item{
    text-align: center;
}
.ant-menu-item-group{
    flex-direction: column;
    display: flex;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item, :where(.css-dev-only-do-not-override-12jzuas).ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-submenu-title {
    padding-inline: 16px 16px;
}
:where(.css-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after, :where(.css-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected::after, :where(.css-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after, :where(.css-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected::after {
    border-bottom-width: 2px;
    border-bottom-color:unset;
}
.ant-menu-title-content{
    text-align: left;
}
.ant-menu-vertical{
    display: flex;
    flex-direction: row;
}
.ant-menu-horizontal{
    width: 100%;
    justify-content: end;
}
.ant-menu-item-selected{
    background: #efefef !important;
    color: #000 !important;
    border: none;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected::after{
    border: none;
}
    
:where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item:hover::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu:hover::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-item-active::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-active::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-active::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-item-open::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-open::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after, :where(.css-dev-only-do-not-override-12jzuas).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-open::after{
    border: none;
}