.casestudies{
    font-family: poppins;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #000;
    color: #fff;
    margin-top: 100px;
}
.casestudies_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.casestudies h1{
    font-family: poppins;
    font-size: 60px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 80px;
}
.casestudies_box{
    height: 336px;
    margin: 50px 20px;
    margin-bottom: 120px;
}