.circle{
    background: #efefef;
    padding: 3vh;
    margin: 0 1%;
    height: 360px;
    width: 360px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}

.circle:hover{
    background-color: #fc3a3a;
    transition: 1s;
}
.circle:hover p{
    color: #ffffff;
    transition: 0.5s;
}
.circle:hover h2{
    color: #fff;
    transition: 0.5s;
}
.circle:hover .class-1{
    fill: #fff;
    transition: 0.5s;
}
.servicecircles{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 140px;
}
.circle h2{
    color: #353535;
    line-height: 24px;
    position: relative;
    margin: 1vh 0;
    font-family: 'Poppins';
    font-weight: 500;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
}
.circleicon{
    width: 64px;
    position: relative;
    margin: 1vh 0;
}
.circle p{
    color: #353535;
    position: relative;
    margin: 1vh 0;
    text-align: center;
    text-align: center;
    padding: 1vh;
    min-height: 160px;
    font-size: 13px;
    line-height: 18px;
}
.top{
    bottom: 80px;
    position: relative;
}
.class-1{
    fill: #fc3a3a;
  }

  .clickmore{
    display: flex;
    align-items: center;
    justify-content: center;
   
    flex-direction: column;
    cursor: pointer;
  }
  .clickmore img{

    width: 16px;
    height: 16px;
    margin-top: 5px;
    
  }
  .clickmore p{
    color: #fc3a3a;
    margin-top: 40px;
    font-size: 18px;
    text-transform: uppercase;
  }
