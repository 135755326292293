.footerdark{
    background-position: center;
    font-family: poppins;
    padding-top: 100px;
    background: #111;
    color: #f7f7f7;
}
.footerdark h1{
    font-family: poppins;
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    margin-bottom: 20px;
}
.footerdark_box{
    margin: 100px 20px;
    font-family: poppins;
}
.footerdark_container{
    margin: auto;
    width: 1140px;
    display: flex;
    flex-direction: initial;
    align-items: flex-start;
    justify-content: center;
    
}
.container_copyrights{
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: initial;
    align-items: flex-start;
    justify-content:flex-start;
    padding: 50px 0;
    border-top: 1px solid #c7c7c7;
}
.footerdark_columns{
    margin: auto;
    width: 100%;
    margin-bottom: 50px;

}
.footerdark_columns h2{
    margin: 20px 0 10px 0;
    text-transform: uppercase;
    color: #fff;
    font-family: poppins;

}
.logo_footerdark{
    width: 50%;
}
.footerdark_menu li{
    text-decoration: none;
    list-style-type: none;
    text-transform: uppercase;
    margin: 10px 0;
    font-size: 12px;
}
.menu_p{
    margin: 10px 0;
    font-size: 12px;

}



p{
    font-family: poppins;
}
.sm{

    margin-right: 20px;
    font-size: 18px;
    color: #c7c7c7;
}
.copyrights a{
    font-weight: 700;

}
@media (max-width: 768px){
    .footerdark h1 {
        font-size: 24px;
    }
    .footerdark_container h2 {

        font-size: 18px;

    }
    .copyrights {
        margin: 0 5%;
    }
    .copyrights p {
        font-size: 12px;
    }
    .footerdark_container_copyrights {
    padding: 20px 0;
}


}

@media (min-width:769px) and (max-width:1024px){
    .footerdark p {
        font-size: 14px;
        line-height: 24px;
    }
    
}